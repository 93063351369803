
mainbar{
    grid-area: mainbar;
    color: #202020;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FCFCFF;
    font-family: "Segoe UI";
  }
  
  mainbar > ul, .mobile-menu > ul{
    list-style-type: none;
    margin: 0;
    padding:0;
    overflow: hidden;
  }
  
  mainbar > ul > li {
    float: left;
  }
  
  mainbar > ul > li > a{
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    color: #202020;
  }
  
  mainbar > ul > li > a:hover, .mobile-menu  > ul > li > a:hover{
   color: #b88715;
   cursor: pointer;
   font-weight: 500;
  }
  
  .mobile-menu  > ul > li > a{
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    color: #202020;
  }

  .mobile-menu{
      display: none;
      width: 100vw;
      cursor: pointer;
      animation: 1.2s ease-out 0s 1 slide;
      box-shadow: inset -2px 5px 33px 6px rgba(0,0,0,0.25);
      overflow: hidden;
  } 

  .show{
    display: block;
  }

  #btnMobile{
    cursor: pointer;
    font-weight: 600;
    padding: 14px 16px;
    display: none;
    width: 100vw;
    align-items: center;
    justify-content: center;

  }

  #btnMobile > img{
    height: 5vmin;
    width: 5vmin;
    display: flex;
  }

  @media(max-width: 810px) {
    .menu{
      display: none;
    }
    #btnMobile {
          display: grid;
        }
 }
 @media(min-width: 810px) {

 #divMobile {
        display: none !important;
      }
}
  
 @keyframes slide {
  from {
    opacity: 0;

  }
  to {
    opacity: 1;
  }
  
 }