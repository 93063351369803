.stations {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    width: 43vw;
    padding-bottom: 20px;
}

.stations > div > p {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
    margin-top: 0;
    
}

.description {
    margin-top: 15px;
    width: 65%;
    margin-left: 10px;
}

.title {
    font-weight: 700;
}

.photo {
  height: 140px;
  width: 35%;
}

.imgPhoto{
  background-color: #FCFCFC;
  box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  outline: 2px solid transparent;
}

.photo > img{
    box-shadow: -2px 5px 33px 6px rgba(0,0,0,0.5);
    object-fit:cover;
}

.contain{
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
}

@media screen and (max-width: 800px) {
    .container {
      flex-direction: column;
    }
    .stations {
      width: 90vw;
    }
  }

.main-2{
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%,   rgb(253, 186, 29)  15%,  rgb(253, 186, 29)  85%, rgb(255, 255, 255) 100%);

  }

  .contain-header{
    padding-bottom: 40px;
  }

.map-link{
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    color: #202020;
}

.map-link:hover{
    cursor: pointer;
    font-weight: 500;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: relative;
  display: flex;
}