.historyContent{
   width: 80vw;
   padding: 10px;
   padding-right: 0 !important;
}

#history {
    margin: 0;
    padding: 0;
}

#history > p{
   padding: 10px;
   line-height: 1.7;
   padding-bottom: 40px;
   text-align: justify;
   text-justify: inter-word;

 }
 
 @media screen and (max-width: 768px) {
    #history{
        height: 100vh;
     }
  }