.modal{
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(49,49,49,0.5);
    z-index: 9999;
}
.modal-content {
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40%;
    left: 0;
    right: 0;

    align-items: center;
    justify-content: center;

    text-align: left;


    width: 10vw;
    height: 200px;
    background: #FDFAF3;
    max-width: 600px;
    min-width: 300px;
    border-radius: 40px;

    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.modal-content > h2 {
    width: 48%;
    cursor: default;
}

/*Dots...*/
.dot-pulse {
    position: relative;
    left: -10005px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #EE8F1D;
    color: #EE8F1D;
    box-shadow: 10005px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
    top: 3px !important;

  }
  .dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #EE8F1D;
    color: #EE8F1D;
  }
  .dot-pulse::before {
    box-shadow: 9990px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
  }
  .dot-pulse::after {
    box-shadow: 10020px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
  }
  
  @keyframes dot-pulse-before {
    0% {
      box-shadow: 9990px 0 0 -5px;
    }
    30% {
      box-shadow: 9990px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9990px 0 0 -5px;
    }
  }
  @keyframes dot-pulse {
    0% {
      box-shadow: 10005px 0 0 -5px;
    }
    30% {
      box-shadow: 10005px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10005px 0 0 -5px;
    }
  }
  @keyframes dot-pulse-after {
    0% {
      box-shadow: 10020px 0 0 -5px;
    }
    30% {
      box-shadow: 10020px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10020px 0 0 -5px;
    }
  }



  @media screen and (max-width: 430px) {

    .dot-pulse {
    left: -9990px;
  }

  .modal{
    width: 110vw !important;
  }

  .modal-content {
    left: -10px
  }

  
  .modal-content > h2 {
    margin-left: -30px
  }
  
  }