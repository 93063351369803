
.disable{
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
}

.companies {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    width: 43vw;
    padding-top: 20px;
}

.companies > div > p {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
    margin-top: 0;

    display: inline-block;
}

.companiesFormPTag{
  width: 100%;
  text-align: center;

}

.ServiceDescription {
    width: 70%;
    align-items: center;
    display: flex;
}

.btnTitle {
    font-weight: 700;

}

.contain{
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
}

.main-2{
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%,   rgb(253, 186, 29)  15%,  rgb(253, 186, 29)  85%, rgb(255, 255, 255) 100%);

  }

  .contain-header{
    padding-bottom: 40px;
  }

/*Botón*/
/*--------------------------------------------------------------------*/
.btnPhoto {
  height: 140px;
  width: 30%;
  background-color: #FCFCFC;
  padding: 10px;
  box-shadow: -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  cursor: pointer;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  touch-action: manipulation;
  background-image: linear-gradient(to bottom, #fff, #f8eedb);
  outline: 2px solid transparent;

}

.btnPhoto:hover {
  background-image: linear-gradient(to bottom, #F0F0F0, #F0F0F0);
  background-color: black;

  text-decoration: none;
  transition-duration: 0.1s;
  transform: translateY(-2px);

}

.btnPhoto:disabled {
  background-color: #FAFBFC;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959DA5;
  cursor: default;
}

.btnPhoto:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transform: translateY(0.125rem);
}

.btnPhoto:focus {
  outline: 1px transparent;
  box-shadow: rgba(72, 35, 7, .46) 0 0 0 4px, -6px 8px 10px rgba(81, 41, 10, 0.1), 0px 2px 2px rgba(81, 41, 10, 0.2);

}

.btnPhoto:before {
  display: none;
}

.btnPhoto:-webkit-details-marker {
  display: none;
}

/*--------------------------------------------------------------------*/

.orderFormOff{
  opacity: 0;
  display: none;

}

.orderFormOn{
  display: block;
  opacity: 100%;


}

.btnServicies{
  border-bottom: none !important;
  box-shadow: none;
  background-image: linear-gradient(to top, #fff, #f8eedb);
  border-radius: 15px 15px 0px 0px;
}
@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
  }

  .companies {
    width: 90vw;
  }
}

@media screen and (max-width: 430px) {
  .contain{
    width: 100vw !important;

  }
  .companies {
    padding-left: 10px;

  }

  .orderFormOn {
    width: 90vw;
  }

  .companiesFormPTag{
    padding-left: 10px;

  }

  .contain-header{
    font-size: 28px;
  }

  .btnPhoto {
    height: 165px;
    width: 40%;
  
  }

  .OrdersForm{
    margin-top: -2px;
  }

  .ServiceDescription > table > tr > td{
    font-size: 12px;
}



  


 
}