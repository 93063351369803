main {
    color: black;
    min-height: 70vh;
    grid-area: main;

    align-items: center;
    justify-content: center;
    display: grid;
    background: linear-gradient(180deg, rgb(253, 186, 29) 0%,   rgb(255, 255, 255)  100%);
  }
  
  main > h1 {
    text-align: center;
    color: #ec1f26b9;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px #272929,
        1px 2px 1px wheat,
        1px 3px 1px wheat,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(8, 8, 8, 0.4);

  }

  main > img {
    padding-bottom: 10px;
  }

  .Logo{
    align-items: center;
    display:block;
    margin: 0 auto;
    max-width: 1000px;
    position:relative;
  }

  .price-section{
    width: 95vw;
    margin-bottom: 10px;
    box-shadow: -2px 5px 33px 6px rgba(0,0,0,0.5);
    border: solid #D3691C 2px;
    border-radius: 10px;

    align-items: center;
    margin: 0 auto;
    max-width: 1000px;
    margin-left: 10px;
  }  
  
  .price-section-inside-content-title > p {
    padding-top: 0;
    margin: 0;
  }
  
  .price-section-header-1{
    padding-top: 10px !important;
    font-weight: 500;
  }
  
  .price-section-header-2{
    font-weight: 500;
    font-size: 14px;
  }

  .price-section-inside{
    overflow: hidden;
    position: relative;
    padding-bottom: 15px;
  }
  
.price-section-inside-content{
    position: relative;
    z-index: 1;
    padding-left: 5px;
    font-family: "Segoe UI";
  }
    
.price-section-inside-background{
    opacity: 0.1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 0;
    
  }  

  .price-section-header-3{
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
  
  }
  
.horizontal-line{
    border-bottom: solid #FEDD90 1px;
  }
  
  .vertical-line{
    border-right: solid #FEDD90 1px;
  }

.centerText{
    text-align: center;
  }
  
  sidebar {
    color: 202020;
    min-height: 75vh;
    grid-area: sidebar;
    justify-content: left;
    background-color: #FCFCFF;
    font-size: calc(10px + 1vmin);
    padding-left: 5px;
  }

  
table {
  border-collapse: collapse;
  margin-top: 5px;
  width: 98%;
  margin-left: 5px;
  
}

th {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  padding: 3px;
}

td {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  padding: 4px;
  border-bottom: solid 1px #D3691C;
}

.map {
  border: 0;
  border-radius: 15px;
  max-width: 1000px;
  width: 95vw;
  position: relative;

}

.map-section{
  align-items: center;
  display: flex;
  margin: 0 auto;
  position: relative;
  padding-bottom: 15px;
}

@media(max-width: 430px) {
  .map {
    margin-left: -30px;
  }
  main > h1 {
    margin-left: -20px;
  }
}