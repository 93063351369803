.gallery{
  width: 100vw;
}

.gallery-container{
  align-items: center;
  display: flex;
  height: 300px;
  margin: 0 auto;
  position: relative;
}

.gallery-item{
  height: 150px;
  opacity: 0;
  position: absolute;
  transition: all 0.8s ease-in-out;
  width: 270px;
  z-index: 0;
  border-radius: 15px;
  object-fit:cover;

}

.gallery-item-1{
  left: 15%;
  opacity: .4;
  transform: translateX(-50%);
}

.gallery-item-9{
  left:85%;
  opacity: .4;
  transform: translateX(-50%);
}

.gallery-item-2, .gallery-item-8{
  height: 200px;
  opacity: .8;
  width: 330px;
  z-index: 1;
}

.gallery-item-2{
  left: 30%;
  transform: translateX(-50%);
}

.gallery-item-8{
  left:70%;
  transform: translateX(-50%);
}

.gallery-item-3{
  left: 30%;
  transform: translateX(-50%);
}

.gallery-item-7{
  left:70%;
  transform: translateX(-50%);
}


.gallery-item-4{
  left: 40%;
  transform: translateX(-50%);
}

.gallery-item-6{
  left:60%;
  transform: translateX(-50%);
}


.gallery-item-5{
  box-shadow: -2px 5px 33px 6px rgba(0,0,0,0.5);
  height: 250px;
  width: 380px;
  left:50%; 
  transform: translateX(-50%);
  opacity: 1;
  z-index: 4;

}

@media (max-width: 1000px){
  .gallery-container{
   width: 60vw;
  }
}

















gallery{
  grid-area: gallery;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(253, 186, 29) 100%);

}

