.container {
  display: grid;
  min-height: 100vh;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr, 2fr, 2fr, 1fr;
  grid-template-areas:
    "header header header"
    "mainbar mainbar mainbar"
    "gallery gallery gallery"
    "main main main"
    "footer footer footer";
    background-color: #FCFCFF;
    min-width: 370px;
}

.bottom-container{
  background: linear-gradient(180deg, rgb(253, 186, 29) 0%,  rgb(255, 255, 255) 100%);
  
}

/* Personal CCS */
@media(max-width: 810px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "mainbar"
      "gallery"
      "main"
      "footer";
  }

  header > h1 {
    font-size: calc(10px + 1vmin);
  }
  
}



