.l404 {
  height: 100vh;
  width: 98vw;
}

#constructionDiv{
  padding-left: 10px;
}

@media(max-width: 935px) {
  .lCartoon{
      height: 40vmin;
      width:40vmin;
  }
}

@media screen and (max-width: 1024px) {
  #constructionDiv{
    display: inline-flex;
    padding-left: 0;

 }

 .l404 {      
  height: 50vh;
}

}

@media screen and (max-width: 430px) {

.l404 {      
margin: 5px;
}

}