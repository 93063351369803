.main {
    display: block;
    min-height: 10vh;
    height: 100vh;
    background: linear-gradient(180deg,  rgb(255, 255, 255) 0%, rgb(253, 186, 29)   100%);
  }

  .main > div{
    display: flex;
    justify-content: center;
    position: relative;
  }

   img {
    object-fit: contain;
    position: relative;
    display: flex;
  }
  
  .l404 {
    height: 40vmin;
    width: 65vmin;
  }
  
  .c404 {
    height: 20vmin;
    width: 110vmin;
  }
  
  .lCartoon {
    position: fixed;
    align-items: center;
    height: 48vmin;
    width:48vmin;
    bottom: 0;
    left: 0;
  }

  @media(max-width: 935px) {
    .lCartoon{
        height: 40vmin;
        width:40vmin;
    }
  }