.App-logo {
  height: 25vmin;
  width: 50vmin;
  display: flex;
}

.others-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.others-logo {
  text-align: center;
  position: relative;
  height: 100%;
  width: 100%;
}

.others-logo> div {
  position: absolute;
  bottom: 0px;
}

header {
  color: #202020;
  min-height: 10vh;
  grid-area: header;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  background-color: #FCFCFF;

  padding-top: 20px;
}