.modal2{
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 9999;
}
.modal-content2 {
    display: flex;
    flex-direction: row;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    left: 0;
    right: 0;

    align-items: center;
    justify-content: center;

    width: 10vw;
    height: auto;
    background: #FDFAF3;
    max-width: 600px;
    min-width: 300px;
    border-radius: 40px;

    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.modal-content2 > h2 {
    width: 80%;
    text-align: center;
    cursor: default;
    padding-left: 32px;
}

.modal-content2 > img {
    width: 70px;
    height: 50px;
    object-fit: fill;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding-right: 10px;
    padding-top: 3px;
}


@media screen and (max-width: 430px) {

  .modal2{
    width: 110vw !important;
  }

  .modal-content2 > h2 {
    font-size: 20px;
}
  }