.serviceDivForm {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fcfcfc;
    background-image: linear-gradient(to bottom, #fff, #f8eedb);

    transition: all .1s cubic-bezier(0.4, 0, .2, 1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 1px solid rgba(27, 31, 35, 0.15);
    animation: 1.2s ease-out 0s 1 slide;
    outline: 2px solid transparent;
    touch-action: manipulation;
    border-radius: 0px 15px 15px 15px;
    border-top: none !important;
    margin-left: 10px;
    width: 86.5vw;
    padding: 10px;
  }

.formInput{
    display: flex;
    flex-direction: column;
    width: 280px;
}

.fiTwo{
  flex-direction: row;
  justify-content: space-between;

}


.fiTwo > input {
  width: 55%;
}


.serviceForm{
  padding: 10x 60px;
  margin-top: 10px;
  border-radius: 10px;

}

/* Botón */
.formInput>button {

  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  border-width: 0;
  appearance: none;
  align-items: center;
  box-sizing: border-box;
  color: #36395A;
  background-color: #F0F0F0;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  width: 215px;
  position: relative;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.formInput>button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.formInput>button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.formInput>button:active {
  background-color: #EDEFF2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transform: translateY(0.125rem);
}

.formInput>button:disabled{
  box-shadow: none;
  border: solid gray 1px;
  background-color: lightgray;
  color: darkgray;
}

.formInput>button:disabled:focus {
  box-shadow: none;
}

.formInput>button:disabled:hover{
  box-shadow: none;
  transform: none !important;
  cursor: default;
}

.formInput>button:disabled:active {
  background-color: lightgray;
  box-shadow: none;
  transform: none;
}


/* Botón */

.formInput>input{
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
}

.formInput>select{
  padding: 15px;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid gray;
  color: black;

}

.formInput>label{
  font-size: 12px;
  color: gray;
}

.formInput>span{
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
  
}

.formInput>input:invalid[focused="true"]{
  border: 1px solid red;
}

.formInput>input:invalid[focused="true"] ~ span{
  display: block;
}



/*Modal: Logic*/
.mbError, .mbSent, .mbLoading{
  opacity: 0;
  transition: opacity .6s linear;
}
.endingBanner{
  opacity: 0;
  transition: opacity .6s linear;

  text-transform:none;
  text-align: center;
  width: 500px;
  padding: 30px 0px;
  display: none;

}

.RemoveModalBox{
  display: none !important;
}

.ShowModalBox{
  opacity: 1;
}


.ShowEndingBanner{
  display: block;
}


/*Phone: Logic*/
@media screen and (max-width:430px){
  
  .serviceDivForm{
      width: 85vw;
      margin-left: 20px;
  }

  .endingBanner>h3{
    font-size: 18px;
  }
 
}
