.main-3{
    background: linear-gradient(180deg, rgb(255, 255, 255) 0%,   rgb(253, 186, 29)  15%,  rgb(253, 186, 29)  85%, rgb(255, 255, 255) 100%);
    height: 100vh;  
}

.form-container{
    background-color: #FCFCFC;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgb(0,0,0,0.35);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 40px;
    margin-bottom: 80px;
}

.form-container span{
    font-size: 14px;
    text-align: center;
}

.form-container button {
    background-color: #FDBB21;
    color: #fff;
    font-size: 12px;
    padding: 10px 45px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight:600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.form-container h1{
    margin-top: 0;
    text-align: center;
}

.form-container form, .form-inside{
    background-color: #FCFCFC;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
}

.form-inside a{
    color: #FCFCFC;
    background-color: #E97103;
    border: transparent solid 1px;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    border-radius: 8px;
    font-weight:600;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 14px;
    box-shadow: inset 0 0 10px #f8a100;

}

.form-inside a:hover{
    color: black;
    background-color: #f8e1aa;
}

.form-container input , textarea{
    background-color: #eee;
    border: none;
    margin: 8px 0;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize:  none;
}

.active{
    background-color: black;
}

@media screen and (max-width: 768px) and (  min-width: 650px  ) { 

    .form-container{
        width: 600px;
        min-height: 300px;
    }
}

@media screen and (max-width: 430px) {

    .form-container {
        width: 400px;
        min-height: 300px;
    }

    .form-inside a {
        font-size: 16px;
    }
}
